import { template as template_3cb3ff41b72a48e283961c9bab29ecc5 } from "@ember/template-compiler";
const FKText = template_3cb3ff41b72a48e283961c9bab29ecc5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
