import { template as template_555a9605788d403abf97fcfbc141f07d } from "@ember/template-compiler";
const SidebarSectionMessage = template_555a9605788d403abf97fcfbc141f07d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
