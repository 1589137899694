import { template as template_ed62b3a5daa3467bbc5ee5f6c35c4f3d } from "@ember/template-compiler";
const FKLabel = template_ed62b3a5daa3467bbc5ee5f6c35c4f3d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
